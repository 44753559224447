import { useContext, useEffect, useState } from "react";
import { BASE_URL } from "../../../api/config";
import { AppTokenContext } from "../../../contexts/AppTokenContext";
import SingleFitkids from "./SingleFitkids";
import "./Fitkids.scss";

const FitKids = () => {
  const [fitkids, setFitkids] = useState([]);
  const [error, setError] = useState(undefined);
  const { appToken, setAppToken } = useContext(AppTokenContext);

  // Search
  const [query, setQuery] = useState("");

  // Filtered fitkids based on group
  const [filteredFitkids, setFilteredFitkids] = useState([]);

  // Active group button
  const [activeGroup, setActiveGroup] = useState("all");

  useEffect(() => {
    fetch(`${BASE_URL}/fitkids`, {
      method: "GET",
      headers: { "content-type": "application/json", "X-Authorization": appToken },
      mode: "cors",
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((result) => {
        setFitkids(result.data);
        setFilteredFitkids(result.data); // Използвайте всички fitkids като първоначално филтрирани
      })
      .catch((error) => {
        setError("User could not be authenticated");
      });
  }, []);

  // Функция за филтриране на fitkids по група
  const filterByGroup = (group) => {
    if (group === "all") {
      setActiveGroup("all");
      // Ако е избрана "all", покажете всички fitkids
      setFilteredFitkids(fitkids);
    } else {
      setActiveGroup(group);
      // Иначе, филтрирайте по избраната група
      setFilteredFitkids(fitkids.filter((fitkid) => fitkid.group === group));
    }
  };

  return (
    <>
      <div className="clients-holder">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>

        <div className="group-buttons">
          {/* Бутон за показване на всички fitkids */}
          <button
            className={activeGroup === "all" ? "active" : ""}
            onClick={() => filterByGroup("all")}
          >
            всички
          </button>

          {/* Бутони за показване на fitkids по група */}
          <button
            className={activeGroup === "small" ? "active" : ""}
            onClick={() => filterByGroup("small")}
          >
            малка
          </button>
          <button
            className={activeGroup === "middle" ? "active" : ""}
            onClick={() => filterByGroup("middle")}
          >
            средна
          </button>
          <button
            className={activeGroup === "big" ? "active" : ""}
            onClick={() => filterByGroup("big")}
          >
            голяма
          </button>
        </div>

        <div className="clients">
          {filteredFitkids
            .filter((fitkid) =>
              fitkid.name.toLowerCase().includes(query.toLowerCase())
            )
            .map((fitkid) => (
              <SingleFitkids key={fitkid._id} client={fitkid} />
            ))}
        </div>
      </div>
    </>
  );
};

export default FitKids;
