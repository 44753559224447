import "./ManagerProfile.scss";
import { useContext, useEffect, useState } from "react";
import { AppTokenContext } from "../../contexts/AppTokenContext";
import { BASE_URL } from "../../api/config";
import { AppUserContex } from "../../contexts/AppUserContext";
import CardsCounter from "../../components/cards/cards-counter/CardsCounter";
import { MyClients } from "./my-clients/MyClients";


const ManagerProfile = () => {
    const { appToken, setAppToken } = useContext(AppTokenContext);
    const { appUser, setAppUser } = useContext(AppUserContex);
    const [error, setError] = useState(undefined);

    const [clients, setClients] = useState([]);
    const [activeClients, setActiveClients] = useState([]);
    const [activeCards, setActiveCards] = useState([]);

    // New code 
  //  console.log(appUser._id);
    const cardsSort = (users) => {
        const ids = users .map(item => item._id);
        setClients(ids);
      }
    

  
    useEffect(() => {
      fetch(`${BASE_URL}/clients`, {
        method: "GET",
        headers: { "content-type": "application/json", "X-Authorization" :  appToken},
        mode: "cors",
      })
        .then((response) => {
          if (!response.ok) throw new Error(response.status);
          else return response.json();
        })
        .then((result) => {
          // setClients(result.data);
          setActiveClients(result.count)
          cardsSort(result.data)
        })
        .catch((error) => {
          setError("User could not be authenticated");
        });
    }, [] );
    

    return (
            <div className="manager-profile">
                <h1>ИНФОРМАЦИЯ</h1>
                {clients.length > 0 && <CardsCounter clients={clients} />}
                <h1>Моите клиенти</h1>
                <MyClients />
            </div>
    )
}

export default ManagerProfile;