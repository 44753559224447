import React, { useEffect, useState  } from "react";
import { useContext,} from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClientContext } from "../../../contexts/ClientContext";
import { BASE_URL } from "../../../api/config";
import { AppTokenContext } from "../../../contexts/AppTokenContext";

const SingleFitkids = ({
    client
}) => {
    const navigate = useNavigate();
    const { appToken, setAppToken } = useContext(AppTokenContext);
    const { currentClient, setCurrentUser } = useContext(ClientContext);
    const [clientCards, setClientCards] = useState([]);
    const [error, setError] = useState(undefined);

    // const cardsSort = (cards) => {

    //     const currentDate = new Date();
    
    //     const activeCards = cards.filter(card => new Date(card.end).getTime() >= currentDate.getTime() && card.trainingsLeft > 0);
    //     setClientCards(activeCards);
    //     }

    // useEffect(() => {
    //     fetch(`${BASE_URL}/fitkids/${client._id}/cards`, {
    //       method: "GET",
    //       headers: { "content-type": "application/json", "X-Authorization" :  appToken},
    //       mode: "cors",
    //     })
    //       .then((response) => {
    //         if (!response.ok) throw new Error(response.status);
    //         else return response.json();
    //       })
    //       .then((result) => {
    //         cardsSort(result.data);
    //       })
    //       .catch((error) => {
            
    //         setError("User could not be authenticated");
    //       });
    //   }, []);

    const onClickUser = () => {
        
        setCurrentUser(client);
        navigate(`/fitkids/${client._id}`);
    }
    console.log(client);
    return (
        
        <div className="client-holder">
            <div className="name-img-holder">
                <img src={client.image} alt="avatar" />
                <h1>{client.name}</h1>
                {/* <div className={`dot ${clientCards.length > 0 ? "green-dot" : "red-dot"}`} ></div> */}
            </div>
        <button className="btn-only-text-outline-small" onClick={onClickUser}>Details</button>
            
            
        </div>
    )
}


export default SingleFitkids;