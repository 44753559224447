import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../api/config";
import { AppTokenContext } from "../../../contexts/AppTokenContext";
import { ClientContext } from "../../../contexts/ClientContext";
import "./CreateFitKids.scss";

const CreateFitKids = () => {
  const navigate = useNavigate();
  const { appToken, setAppToken } = useContext(AppTokenContext)
  const { currentClient, setCurrentUser } = useContext(ClientContext);

  const [values, setValues] = useState({
    name: "",
    group: ""
  });
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(undefined);
  const [showError, setShowError] = useState(true);
    // Добавяме стейт за следене на избраната група
    const [selectedGroup, setSelectedGroup] = useState("");

  // ==========================================
  const [validationErrors, setValidationErrors] = useState({});
  const validateForm = () => {
    const errors = {};
  
    if (!values.name.trim()) {
      errors.name = "Името на детето";
    }
  
    setValidationErrors(errors);

    setShowError(true);
    // Hide error after 3 seconds
    setTimeout(() => {
      setShowError(false);
      setValidationErrors({});
    }, 3000);

    return Object.keys(errors).length === 0;
  };
  // ==========================================
 // Функция, която се изпълнява при промяна в избора на група
  const onGroupChange = (e) => {
    setSelectedGroup(e.target.value);
  };

  const onChangeHandler = (e) => {
    setValues((state) => ({ ...state, [e.target.name]: e.target.value }));
    // TODO Form validation
    setIsValid(true);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // TODO make post request
      // console.log("Create new client");
      const result = await fetch(`${BASE_URL}/fitkids`, {
        method: "POST",
        headers: { "content-type": "application/json", "X-Authorization" :  appToken},
        mode: "cors",
        body: JSON.stringify({
          name: values.name,
          group: selectedGroup,
        }),
      })
        .then(async (response) => {
          const res = await response.json()
          if (!response.ok) {
            const err = res.error
            throw new Error(err)
          }
          else return res;
        })
        .then((result) => {
          const id = result._id;
          // console.log(id);
          setCurrentUser(result)
          navigate(`/fitkids/${id}`);
        })
        .catch((error) => {
          setError(error.message);
          setShowError(true);
          // Hide error after 3 seconds
          setTimeout(() => {
            setShowError(false);
            setError(undefined)
          }, 3000);

        });
    }
  };

  return (
    <form id="create" onSubmit={onSubmitHandler}>
      <div className="client-profile mobile-pages">
        {error ? <div className="error">{error}</div> : null}
        <h1>Създавене на клиента</h1>
        <div className="client-profile-info">
          <div className="input-holder">
            <label
              htmlFor="clientName"
              className="icon-user-white row-icons"
            ></label>
            <input
              value={values.name}
              onChange={onChangeHandler}
              type="text"
              id="name"
              name="name"
              placeholder="Име на клиента"
            />
          </div>
        </div>

        {/* Бутони за избор на група */}
        <div className="group-buttons">
          <label>
            <input
              type="radio"
              value="small"
              checked={selectedGroup === "small"}
              onChange={onGroupChange}
            />
            <p>Малка</p>
            
          </label>
          <label>
            <input
              type="radio"
              value="middle"
              checked={selectedGroup === "middle"}
              onChange={onGroupChange}
            />
            <p>Средна</p> 
          </label>
          <label>
            <input
              type="radio"
              value="big"
              checked={selectedGroup === "big"}
              onChange={onGroupChange}
            />
            <p>Голяма</p>
          </label>
        </div>


        <button type="submit" className="btn-only-text-outline-small">
          Запази
        </button>
      </div>
    </form>
  );
};


export default CreateFitKids;