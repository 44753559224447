import "./cardsCounter.scss"
import React, { useContext, useEffect, useState } from 'react'
import { AppTokenContext } from "../../../contexts/AppTokenContext";
import { BASE_URL } from "../../../api/config";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const CardsCounter = ({clients}) => {
    const { appToken, setAppToken } = useContext(AppTokenContext);
    const [error, setError] = useState(undefined);
    const [allUsersCards, setUsersCards] = useState([])
    const [usersActiveCards, setUsersAvtiveCards] = useState([]);

    const [cardsByMonthAndType, setCardsByMonthAndType] = useState({});

    useEffect(() => {
        const fetchCardsForUserId = (userId) => {
          return fetch(`${BASE_URL}/clients/${userId}/cards`, {
            method: "GET",
            headers: { "content-type": "application/json", "X-Authorization": appToken },
            mode: "cors",
          })
            .then((response) => {
              if (!response.ok) throw new Error(response.status);
              else return response.json();
            });
        };
        
        Promise.all(clients.map(id => fetchCardsForUserId(id)))
          .then((results) => {
            // `results` ще бъде масив от отговорите на всеки API call
            allCards(results)
          })
          .catch((error) => {
            setError("User could not be authenticated");
          });
      
      }, []);
      
      const all = [];
      function allCards(results) {
        results.forEach(item => {
            item.data.forEach(dataItem => {
                all.push(dataItem);
            });
        });
       
        setUsersCards(all)
        const currentDate = new Date();

        const expiredCards = all.filter(card => new Date(card.end).getTime() < currentDate.getTime() || card.trainingsLeft < 1);
        // const activeCards = all.filter(card => new Date(card.end).getTime() >= currentDate.getTime());
        // const activeCards = all.filter(card => new Date(card.end).getTime() >= currentDate.getTime() || card.trainingsLeft > 0);
        const activeCards = all.filter(card => new Date(card.end).getTime() >= currentDate.getTime() && card.trainingsLeft > 0);
        // console.log("Карти с изтекла дата:", expiredCards);
        console.log("Карти без изтекла дата:", activeCards);
        setUsersAvtiveCards(activeCards)
      }

    // карти за всеки месец
        useEffect(() => {
        const countCardsByMonthAndType = (cards) => {
        const count = {};

        cards.forEach(card => {
        const date = new Date(card.start);
        const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`; // година-месец

        if (!count[monthKey]) {
            count[monthKey] = {};
        }

        if (!count[monthKey][card.type]) {
            count[monthKey][card.type] = 0;
        }
        count[monthKey][card.type]++;
        });

        setCardsByMonthAndType(count);
    };

    countCardsByMonthAndType(allUsersCards);
    }, [allUsersCards]);
    // трансформация на картите за Chart
    const transformDataForChart = (data) => {
        const chartData = [];
        Object.entries(data).forEach(([month, types]) => {
          const entry = { name: month };
          Object.entries(types).forEach(([type, count]) => {
            entry[type] = count;
          });
          chartData.push(entry);
        });
        return chartData;
      };

      const chartData = transformDataForChart(cardsByMonthAndType);
      const colorPalette = ['#8884d8', '#82ca9d', '#ffc658', '#e38572', '#58a5bd', '#d1a3ac'];

  return (
    <div className='cardsCounter'>

        {usersActiveCards.length > 0 && <div className="active-cards"><h2>Активни карти</h2> <p>{usersActiveCards.length}</p></div>}
        <div className="cards-chart">
                  <ResponsiveContainer width="100%" height={250}>
                    <AreaChart
                    data={chartData}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    {
                        Object.keys(chartData[0] || {}).filter(key => key !== "name").map((type, index) => (
                        <Area 
                            key={type}
                            type="monotone"
                            dataKey={type}
                            stackId="1"
                            stroke={colorPalette[index % colorPalette.length]}  // Използвайте модулно деление, за да гарантирате, че индексът не излиза извън границите на масива.
                            fill={colorPalette[index % colorPalette.length]}
                        />
                        ))
                    }
                    
                    </AreaChart>
                </ResponsiveContainer>
                </div>
    </div>
  )
}

export default CardsCounter