import React, { useContext } from "react";
import { useParams } from 'react-router-dom';
import { ClientContext } from "../../contexts/ClientContext";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../api/config";
import ClientProfileInfo from "../../components/clients/client-profile-info/ClientProfileInfo";
import ClientProfileCards from "../../components/clients/client-profile-cards/ClientProfileCards";
import ClientProfileDashboard from "../../components/clients/client-profile-dashboard/ClientProfileDashboard";
import { AppTokenContext } from "../../contexts/AppTokenContext";
import AvatarPicker from "../../components/clients/client-avatars/AddAvatar"
import { AppUserContex } from "../../contexts/AppUserContext";
import FitkidsProfileCards from "../../components/clients/client-profile-cards/FitkidsProfileCards";
import AddAvatarKids from "../../components/clients/client-avatars/AddAvatarKids";

const FitkidsProfile = () => {
  const { appUser, setAppUser } = useContext(AppUserContex);
  const { appToken, setAppToken } = useContext(AppTokenContext);
  const { currentClient, setCurrentUser } = useContext(ClientContext);

  const userRole = appUser ? JSON.stringify(appUser.type).replace(/"/g, '') : null;

  const [error, setError] = useState(undefined);

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("cards");

  const userId = useParams(":userId");
  let clientId = userId.clientId === undefined ? appUser._id : userId.clientId;

  const userName = currentClient ? currentClient.name : null;
  const userGroup = currentClient ? currentClient.group : null;

  const [isEditingName, setIsEditingName] = useState(false);
  const [currentName, setCurrentName] = useState(userName);
  const [editingName, setEditName] = useState(userName);

  const [currentGroup, setCurrentGroup] = useState(userGroup);
  const [editingGroup, setEditGroup] = useState(userGroup);

  const [clientAvatar, setClientAvatar] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showError, setShowError] = useState(false);

  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const [isGroupListVisible, setIsGroupListVisible] = useState(false);


  useEffect(() => {
    if (!currentClient) {
      setIsLoading(true);
      fetch(`${BASE_URL}/fitkids/${clientId}`, {
        method: "GET",
        headers: { "content-type": "application/json", "X-Authorization": appToken },
        mode: "cors",
      })
        .then((response) => {
          if (!response.ok) throw new Error(response.status);
          else return response.json();
        })
        .then((result) => {
          setEditName(result.name);
          setCurrentName(result.name);
          setClientAvatar(result.image);
          setCurrentGroup(result.group);
          setCurrentUser(result);
          setIsLoading(false);
        })
        .catch((error) => {
          setError("User could not be authenticated");
          setIsLoading(false);
        });
    } else {
      setEditName(currentClient.name);
      setCurrentName(currentClient.name);
      setClientAvatar(currentClient.image);
      setCurrentGroup(currentClient.group);
    }
  }, [currentClient, setCurrentUser, currentGroup, clientId, appToken]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleInputChange = (event) => {
    setEditName(event.target.value);
  };

  const handleGroupChange = (event) => {
    setEditGroup(event.target.value);
  };

  const editName = () => {
    setIsEditingName(true);
  };

  const toggleEditingGroup = () => {
    setIsEditingGroup(!isEditingGroup);
    setIsGroupListVisible(!isGroupListVisible);
  };



  const handleSaveName = async () => {
    if (editingName === "") {
      setError("Името е задължително");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setError(undefined);
      }, 3000);
    } else if (currentName !== editingName) {
      try {
        const response = await fetch(`${BASE_URL}/fitkids/${clientId}/name`, {
          method: "PUT",
          headers: { "content-type": "application/json", "X-Authorization": appToken },
          mode: "cors",
          body: JSON.stringify({
            name: editingName,
            group: currentGroup,
          }),
        });

        if (!response.ok) {
          throw new Error(response.status);
        }

        const responseData = await response.json();
        setCurrentName(responseData.name);
        setEditName(responseData.name);
        setIsEditingName(false);
      } catch (error) {
        setError("User could not be authenticated");
      }
    } else {
      setIsEditingName(false);
    }
  };

  const handleSaveGroup = async () => {
    if (currentGroup !== editingGroup) {
      try {
        const response = await fetch(`${BASE_URL}/fitkids/${clientId}`, {
          method: "PUT",
          headers: { "content-type": "application/json", "X-Authorization": appToken },
          mode: "cors",
          body: JSON.stringify({
            name: currentName,
            group: editingGroup,
          }),
        });

        if (!response.ok) {
          throw new Error(response.status);
        }

        const responseData = await response.json();
        setCurrentGroup(responseData.group);
        setEditGroup(responseData.group);
        setIsEditingName(false);
        setIsEditingGroup(false);
        setIsGroupListVisible(false);
      } catch (error) {
        setError("User could not be authenticated");
      }
    } else {
        setIsEditingGroup(false);
        setIsGroupListVisible(false);
    }
  };

  const handleCancel = () => {
    setIsEditingName(false);
    setIsEditingGroup(false);
  };

  return (
    <>
      {isOpen && <AddAvatarKids clientAvatar={clientAvatar} setClientAvatar={setClientAvatar} onClose={handleClose} />}
      <main className="client-profile mobile-pages">
        {error ? <div className="error">{error}</div> : null}
        <div className="client-header">
          <div className="row-icons icon-edit" onClick={handleClick}></div>
          <div className="client-avatar">
            {currentClient ? <img src={clientAvatar} alt="avatar" /> : null}
          </div>
          {currentClient ? (
            !isEditingName ? (
              <div className="client-name">
                {editingName}
                {["admin", "trainer", "manager"].includes(userRole) && (
                  <div className="row-icons icon-edit p-div-margin" onClick={editName}></div>
                )}
              </div>
            ) : (
              <div className="edit-icons-input-holder">
              <input type="text" name="name" value={editingName || ""} onChange={handleInputChange} />
              <div className="row-icons icon-save" onClick={handleSaveName}></div>
              <div className="row-icons icon-close-ring" onClick={handleCancel}></div>
            </div>
                  )
          ) : null}

        
        </div>
        <p>{editingGroup}</p>
        <div className="row-icons icon-edit" onClick={toggleEditingGroup}></div>
      {isGroupListVisible && (
        <div className="group-selection">
          <select value={editingGroup} onChange={handleGroupChange}>
            <option value="small">Small</option>
            <option value="middle">Middle</option>
            <option value="big">Big</option>
          </select>
          <div className="row-icons icon-save" onClick={handleSaveGroup}></div>
        </div>
      )}

        <nav className="navigation">
          <div
            className={activeTab === "cards" ? "btn-icons icon-card-active" : "btn-icons icon-card"}
            onClick={() => handleTabClick("cards")}
          ></div>
        </nav>
        {isLoading && <div>Loading...</div>}
        {!isLoading && activeTab === "cards" && currentClient && (
          <div>
            <FitkidsProfileCards userRole={userRole} />
          </div>
        )}
      </main>
    </>
  );
};

export default FitkidsProfile;
