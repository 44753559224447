import React, { useState, useEffect, useContext } from 'react';
import { AppTokenContext } from '../../../contexts/AppTokenContext';
import { CreateCardConext } from '../../../contexts/CreatCardContex';
import { CardDetails } from './CardDetails';

export const AddSubscriptionFitkidsCard = () => {
    const { appToken, setAppToken } = useContext(AppTokenContext);
    const {onSaveCard, clientCreator, clientId, setAddCard} = useContext(CreateCardConext);

    const [selectedCard, setSelectedCard] = useState(null);
    // Set and calculate date
    const [currentDay, setCurrentDay] = useState('');
    const [currentMonth, setCurrentMonth] = useState('');
    const [currentYear, setCurrentYear] = useState('');

    const [futureDay, setFutureDay] = useState('');
    const [futureMonth, setFutureMonth] = useState('');
    const [futureYear, setFutureYear] = useState('');

    const [futureDay30, setFutureDay30] = useState('');
    const [futureMonth30, setFutureMonth30] = useState('');
    const [futureYear30, setFutureYear30] = useState('');

    const newDate = new Date();
    const futureDate = new Date();
    // futureDate.setDate(newDate.getDate() + 35); // Add 5 weeks (35 days) to the current date
    futureDate.setMonth(newDate.getMonth() + 1); 
    futureDate.setDate(0);

    const future30Days = new Date();
    future30Days.setDate(newDate.getDate() + 30); // Add 4 weeks (30 days) to the current date

    useEffect(() => {
        setCurrentDay(newDate.getDate());
            setCurrentMonth(newDate.getMonth() + 1);
            setCurrentYear(newDate.getFullYear());

            setFutureDay(futureDate.getDate());
            setFutureMonth(futureDate.getMonth() + 1);
            setFutureYear(futureDate.getFullYear());

            setFutureDay30(future30Days.getDate());
            setFutureMonth30(future30Days.getMonth() + 1);
            setFutureYear30(future30Days.getFullYear());


      }, []);

    
    //   ------------------------------------

    // Presets type of cards
    const fitKids16 = { 
        "type" : "fitKids-85", 
        "start" : newDate, 
        "end" : futureDate, 
        "trainingsLeft" : "16", 
        "paid" : "true"
    }
    const fitKids8 = { 
        "type" : "fitKids-60", 
        "start" : newDate, 
        "end" : futureDate, 
        "trainingsLeft" : "8", 
        "paid" : "true"
    }
    const fitKids8r = { 
        "type" : "fitKids-75", 
        "start" : newDate, 
        "end" : futureDate, 
        "trainingsLeft" : "8", 
        "paid" : "true"
    }
    const fitKids8b = { 
        "type" : "fitKids-55", 
        "start" : newDate, 
        "end" : futureDate, 
        "trainingsLeft" : "8", 
        "paid" : "true"
    }

    const onChoseCard = (e) => {
        if (e.target.id === 'fitKids16') {
            setSelectedCard(fitKids16);
        }else if (e.target.id === 'fitKids8') {
            setSelectedCard(fitKids8);
        }else if (e.target.id === 'fitKids8r') {
            setSelectedCard(fitKids8r);
        }else if (e.target.id === 'fitKids8b') {
            setSelectedCard(fitKids8b);
        }
    }
 
      const onDeleteCart = () => {
        setSelectedCard(null);
        setAddCard(false)
      }
      const onSaveLocal = () => {
        setSelectedCard(null);
        setAddCard(false)
      }
    
    return(
        <>
        { selectedCard && <CardDetails card={selectedCard} onDeleteCart = {onDeleteCart} onSaveLocal={onSaveLocal}/>}
            <div className='client-cards-holder'>
                <h1>Избери карта</h1>
             
                <div id='fitKids16' className='choose-card fitKids-85' onClick={onChoseCard}>
                    <div id='fitKids16' className='row-icons  icon-user-white'></div>
                    <h2 id='fitKids16'>FIT KIDS 85</h2>
                    <div id='fitKids16' className='row-icons icon-add-ring'></div>
                </div>
                <div id='fitKids8r' className='choose-card fitKids-75' onClick={onChoseCard}>
                    <div id='fitKids8r' className='row-icons  icon-user-white'></div>
                    <h2 id='fitKids8r'>FIT KIDS 75</h2>
                    <div id='fitKids8r' className='row-icons icon-add-ring'></div>
                </div>
                <div id='fitKids8' className='choose-card fitKids-60' onClick={onChoseCard}>
                    <div id='fitKids8' className='row-icons  icon-user-white'></div>
                    <h2 id='fitKids8'>FIT KIDS 60</h2>
                    <div id='fitKids8' className='row-icons icon-add-ring'></div>
                </div>
                <div id='fitKids8b' className='choose-card fitKids-55' onClick={onChoseCard}>
                    <div id='fitKids8b' className='row-icons  icon-user-white'></div>
                    <h2 id='fitKids8b'>FIT KIDS 55</h2>
                    <div id='fitKids8b' className='row-icons icon-add-ring'></div>
                </div>
            </div>

        </>
    )
}